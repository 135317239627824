









































































































































import { MomentHelper } from '@/helpers/moment.helper';
import Vue from 'vue';
import { mapStores } from 'pinia';
import { useBookingsStore } from '@/store/bookings-store';
import { Location } from '@/model/location';
import { Customer } from '@/model/customer';
import { Booking } from '@/model/booking';
import { Service } from '@/model/service';

export default Vue.extend({
  beforeRouteLeave(to, from, next) {
    this.bookingsStore.resetBookingAddForm();
    next();
  },
  data() {
    return {
      MomentHelper
    };
  },
  computed: {
    ...mapStores(useBookingsStore),
    location(): Location {
      return this.bookingsStore.booking.location;
    },
    customer(): Customer {
      return this.bookingsStore.booking.customer;
    },
    selectedBooking(): Booking | null {
      return this.bookingsStore.booking.selectedBooking;
    },
    savingMessage(): string {
      return this.bookingsStore.booking.savingMessage;
    },
    selectedServices(): Service[] {
      return this.bookingsStore.booking.services;
    }
  }
});
